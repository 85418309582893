export interface User {
    Username: string | null;
    Email: string| null;
    Balance: number| null;
    TotalSpent: number| null;
    TotalWon: number| null;
    Debt: number| null;
    HighestWin: number| null;
    Token: string| null;
}

export async function GetUserType(token: string)
{
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    return await fetch('https://feedmyaddiction-db.dallasagsmith.workers.dev/user/credentials', {
        method: 'POST', 
        headers: headers,
        body: JSON.stringify({
            token: token
          }),
      });
}

export async function LoginUser(username: string, password: string)
{
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    return await fetch('https://feedmyaddiction-db.dallasagsmith.workers.dev/user/login', {
        method: 'POST', 
        headers: headers,
        body: JSON.stringify({
            username: username,
            password: password,
          }),
      });
}