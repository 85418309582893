import './App.css';

function Welcome() {
    return (
        <div className="App">
          <header className="App-header">
            <h1>Welcome to Feed My Addiction!</h1>
            <p>This website will host games including slots, table, and arcade games!</p>
            <p>Feel free to create an account so when the games become available you are ready.</p>
          </header>
        </div>
      );
};

export default Welcome;