export interface Game {
    ID: number;
    Name: string;
    Category: number;
    PosterLink: string;
    GameURL: string;
    DescriptionURL: string;
    FirstName?: string | null;
    FirstWin?: string | null;
    SecondName?: string | null;
    SecondWin?: string | null;
    ThirdName?: string | null;
    ThirdWin?: string | null;
}

export interface GameContainer {
    games: Game[];
}


export async function GetGame()
{
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    return await fetch(`https://feedmyaddiction-db.dallasagsmith.workers.dev/games/get`, {
        method: 'GET', 
        headers: headers,
    });
}

export async function GameCreate(name: string, type: string, gitname: string, gitrepo: string, token: string)
{
    const link = `https://api.github.com/repos/${gitname}/${gitrepo}/contents/{file}?ref=main`;

    let gameType = 3;
    if (type === 'slot')
    {
        gameType = 1;
    }
    else if (type === "table")
    {
        gameType = 2;
    }

    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    return await fetch(`https://feedmyaddiction-db.dallasagsmith.workers.dev/games/create`, {
        method: 'POST', 
        headers: headers,
        body: JSON.stringify({
            name: name,
            gameurl: link,
            category: gameType,
            token: token
        }),
    });
}

export async function GameDelete(name: string, token: string)
{
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    return await fetch(`https://feedmyaddiction-db.dallasagsmith.workers.dev/games/delete`, {
        method: 'POST', 
        headers: headers,
        body: JSON.stringify({
            name: name,
            token: token
        }),
    });
}