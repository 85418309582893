import "./leaderboard.css";
import {User} from "./objects/UserLogin";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Game, GameCreate, GameDelete, GetGame } from "./objects/GameGet";


interface AdminPageProps {
    user: User | null;
}



const AdminPage: React.FC<AdminPageProps> = ({ user}) => {
    const [activeTab, setActiveTab] = useState('Game');

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };
    
    
    // useEffect(() => {
    //     const fetchLeaderboardData = async () => {
    //         try {
                
    //             const response = await GetStats();
    //             const data: StatisticsContainer = await response.json();

    //             setNetEarningsList(data.MostEarnings);
    //             setHighestWinList(data.HighestWin);
    //             setTotalLostList(data.MostLost);
    //             setTotalSpentList(data.MostSpent);
    //             setTotalWonList(data.MostWon);

    //         } catch (error) {
    //             console.error('Error fetching leaderboard data:', error);
    //         }
    //     };

    //     fetchLeaderboardData();
    // }, []);


    return(
        <div className="account-body">
            <div className="account-container">
                <div className="account-form">
                        <h1>Admin Panel</h1>
                        <br/>

                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <Link
                                className={`nav-link ${activeTab === 'Game' ? 'active' : ''}`}
                                onClick={() => handleTabClick('Game')}
                                to="#"
                                style={{ color: activeTab === 'Game' ? 'black' : 'green' }}
                                >
                                Games
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                className={`nav-link ${activeTab === 'total-spent' ? 'active' : ''}`}
                                onClick={() => handleTabClick('total-spent')}
                                to="#"
                                style={{ color: activeTab === 'total-spent' ? 'black' : 'green' }}
                                >
                                Users
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                className={`nav-link ${activeTab === 'total-lost' ? 'active' : ''}`}
                                onClick={() => handleTabClick('total-lost')}
                                to="#"
                                style={{ color: activeTab === 'total-lost' ? 'black' : 'green' }}
                                >
                                Loans
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                className={`nav-link ${activeTab === 'api' ? 'active' : ''}`}
                                onClick={() => handleTabClick('api')}
                                to="#"
                                style={{ color: activeTab === 'api' ? 'black' : 'green' }}
                                >
                                API Info
                                </Link>
                            </li>
                        </ul>
                        <GameMenu selectedTab={activeTab} user={user}/>

                        <div id="tab-content">
                            {activeTab === 'net-earnings' && (
                                <div id="net-earnings-content">
                                    <table>
                                        <tr>
                                            <td>{user?.Username}: </td>
                                            <td>${((user?.TotalWon ?? 0) - (user?.TotalSpent ?? 0) - (user?.Debt ?? 0))}</td>
                                        </tr>
                                    </table>
                                    <br/>
                                    <div style={{ maxHeight: '400px', overflowY: 'scroll', border: '2px solid black' }}>
                                        <table className="leaderboard-table" id="net-earnings-table">
                                            
                                        </table>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminPage;




interface GameMenuProps {
    selectedTab: string;
    user: User | null;
}
  
const GameMenu: React.FC<GameMenuProps> = ({ selectedTab, user }) => {
    const [tab, setTab] = useState<string>("create");
    const [createMessage, setCreateMessage] = useState<string>("");
    const [createSelectedOption, setCreateSelectedOption] = useState<string>('other');
    const [createIsChecked, setCreateIsChecked] = useState<boolean>(false);
    const [allGames, setAllGames] = useState<Game[]>([]);
    const nameLength = 25;

    const handleTabClick = (tab: string) => {
        setTab(tab);
    };
    
    const handleCreateOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCreateSelectedOption(event.target.value);
    };

    const handleCreateCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked)
        {
            setCreateIsChecked(true);
        }
        else
        {
            setCreateIsChecked(false);
        }
    };

    async function AddGame(event: React.FormEvent)
    {
        event.preventDefault();

        const name = (document.getElementById("name") as HTMLInputElement | null)?.value;
        const type = (document.getElementById("options") as HTMLInputElement | null)?.value;
        const gitname = (document.getElementById("githubName") as HTMLInputElement | null)?.value;
        const gitrepo = (document.getElementById("githubRepo") as HTMLInputElement | null)?.value;

        try
        {
            if ((name?.length ?? 0) > nameLength)
            {
                setCreateMessage(`Name must be no more than ${nameLength} characters.`);
            }
            else
            {
                let response = await GameCreate(name ?? '', type ?? '', gitname ?? '', gitrepo ?? '', user?.Token ?? '');
                
                setCreateMessage((await response.text()).toString());

                const response2 = await GetGame();
                const data: Game[] = await response2.json();
                
                setAllGames(data);

            }
        }
        catch
        {
            setCreateMessage("Error contacting server.");
        }
    }


    useEffect(() => {
        const fetchGame = async () => {
            try {
                const response = await GetGame();
                const data: Game[] = await response.json();
                
                setAllGames(data);
    
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
    
        fetchGame();
    }, []);

    
    async function DeleteGame(event: React.FormEvent, name: string){
        event.preventDefault();

        await GameDelete(name, user?.Token ?? '');

        const response = await GetGame();
        const data: Game[] = await response.json();
        
        setAllGames(data);
    }


    if (selectedTab === "Game")
    {
        return (
            <>
            <br/>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <Link
                    className={`nav-link ${tab === 'create' ? 'active' : ''}`}
                    onClick={() => handleTabClick('create')}
                    to="#"
                    style={{ color: tab === 'create' ? 'black' : 'green' }}
                    >
                    Add
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                    className={`nav-link ${tab === 'delete' ? 'active' : ''}`}
                    onClick={() => handleTabClick('delete')}
                    to="#"
                    style={{ color: tab === 'delete' ? 'black' : 'green' }}
                    >
                    Delete
                    </Link>
                </li>
            </ul>


            <div id="tab-content">
                <br/>
                {tab === 'create' && (
                    <div id="create-content">
                        <form onSubmit={AddGame} style={{width: '20vw'}}>
                            <input type="text" id="name" className="form-control" placeholder="Name" required/>
                            <br/>
                            <label>
                                <input type="radio" name="options" value="other" checked={createSelectedOption === 'other'} onChange={handleCreateOptionChange}/>
                                Other
                            </label>
                            <br/>
                            <label>
                                <input type="radio" name="options" value="slot" checked={createSelectedOption === 'slot'} onChange={handleCreateOptionChange}/>
                                Slot
                            </label>
                            <br/>
                            <label>
                                <input type="radio" name="options" value="table" checked={createSelectedOption === 'table'} onChange={handleCreateOptionChange}/>
                                Table
                            </label>
                            <br/>
                            <br/>
                            <input type="text" id="githubName" className="form-control" placeholder="Github Username" required/>
                            <br/>
                            <input type="text" id="githubRepo" className="form-control" placeholder="Github Repository Name" required/>
                            <br/>
                            <label htmlFor="agree" style={{paddingLeft: 5}}>I have added DallasSmith1 as a collaborator to the repository (if private).</label>
                            <input type="checkbox" id="agree" name="agree" value="agree" checked={createIsChecked} onChange={handleCreateCheckboxChange}/>
                            <p className="account-error" style={{color: `${createMessage === "Game Added" ? 'green' : 'red'}`}}>{createMessage}</p>
                            <button className={`btn btn-lg btn-success btn-block ${!createIsChecked ? 'disabled' : ''}`} type="submit">Add Game</button>
                        </form>
                    </div>
                )}



                {tab === 'delete' && (
                    <div id="delete-content">
                        <div className="list-group" style={{ maxHeight: '400px', width:'100%', overflowY: 'scroll', borderTop: '1px solid black', borderBottom: '2px solid black' }}>
                            {allGames.map((game, index) => (
                                <>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{fontSize: 20, textAlign: 'left'}}>{game.Name}</td>
                                            <td rowSpan={2}>
                                            <form className="d-flex mt-3" role="search" onSubmit={(e) => DeleteGame(e, game.Name)}>
                                                <button className="btn btn-danger" type="submit">Delete</button>
                                            </form>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{fontSize: 15, color: 'grey'}}>
                                                {game.GameURL}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            </>
        );
    }
    else if (selectedTab === "api")
    {
        return (
            <>
            <br/>
            <h1>Available API Calls</h1>
            <br/>

            <h5>API URL: https://feedmyaddiction-db.dallasagsmith.workers.dev</h5>

            <br/>

            <div className="api-section">
            <h2>User API</h2>
            <ul>
                <li><strong>POST /user/login</strong> - Logs in a user</li>
                <ul>
                <li>Required Headers: <code>username</code>, <code>password</code></li>
                <li>Returns: <code>{'{'} Username, Email, Balance, TotalSpent, TotalWon, Debt, HighestWin, Token {'}'}</code></li>
                </ul>

                <br/>
                <br/>

                <li><strong>POST /user/credentials</strong> - Gets user credentials</li>
                <ul>
                <li>Required Headers: <code>token</code></li>
                <li>Returns: <code>Admin</code> or <code>User</code></li>
                </ul>

                <br/>
                <br/>

                <li><strong>POST /user/create</strong> - Creates a new user</li>
                <ul>
                <li>Required Headers: <code>username</code>, <code>password</code>, <code>email</code></li>
                <li>Returns: <code>"User Created"</code></li>
                </ul>

                <br/>
                <br/>

                <li><strong>POST /user/update</strong> - Updates user password</li>
                <ul>
                <li>Required Headers: <code>username</code>, <code>password</code>, <code>newpassword</code></li>
                <li>Returns: <code>"User Updated"</code></li>
                </ul>

                <br/>
                <br/>

                <li><strong>POST /user/delete</strong> - Deletes a user</li>
                <ul>
                <li>Required Headers: <code>username</code>, <code>password</code></li>
                <li>Returns: <code>"User Deleted"</code></li>
                </ul>

                <br/>
                <br/>

                <li><strong>GET /user/get</strong> - Fetches user data</li>
                <li>Returns: <code>{'[{'} Username, Balance, TotalSpent, TotalWon, Debt, HighestWin {'}]'}</code></li>

                <br/>
                <br/>

                <li><strong>POST /user/temporary</strong> - Creates a temporary password for a user</li>
                <ul>
                <li>Required Headers: <code>username</code></li>
                <li>Returns: <code>"Temporary Password Created"</code></li>
                </ul>

                <br/>
                <br/>

                <li><strong>POST /user/values</strong> - Updates user values</li>
                <ul>
                <li>Required Headers: <code>username</code>, <code>amount</code>, <code>token</code>, <code>spent</code>, <code>gameid</code></li>
                <li>Returns: <code>"User Updated"</code></li>
                </ul>
            </ul>
            </div>

            <br/>
            <br/>

            <div className="api-section">
            <h2>Games API</h2>
            <ul>
                <li><strong>GET /games/get</strong> - Fetches game data</li>
                <ul>
                <li>Returns: <code>{'[{'} GameID, Name, Category, GameURL, FirstName, FirstWin, SecondName, SecondWin, ThirdName, ThirdWin {'}]'}</code></li>
                </ul>

                <br/>
                <br/>

                <li><strong>POST /games/create</strong> - Creates a new game</li>
                <ul>
                <li>Required Headers: <code>name</code>, <code>category</code>, <code>gameurl</code>, <code>token</code></li>
                <li>Returns: <code>"Game Added"</code></li>
                </ul>

                <br/>
                <br/>

                <li><strong>POST /games/update</strong> - Updates a game</li>
                <ul>
                <li>Required Headers: <code>name</code>, <code>category</code>, <code>poster</code>, <code>gameurl</code>, <code>descriptionurl</code>, <code>token</code></li>
                <li>Returns: <code>"Game Updated"</code></li>
                </ul>

                <br/>
                <br/>

                <li><strong>POST /games/delete</strong> - Deletes a game</li>
                <ul>
                <li>Required Headers: <code>name</code>, <code>token</code></li>
                <li>Returns: <code>"Game Removed"</code></li>
                </ul>

                <br/>
                <br/>

                <li><strong>GET /games/load</strong> - Loads a game file</li>
                <ul>
                <li>Required Query Params: <code>gameid</code>, <code>file</code>, <code>type</code></li>
                <li>Returns: Content of the game file</li>
                </ul>
            </ul>
            </div>

            <br/>
            <br/>

            <div className="api-section">
            <h2>Category API</h2>
            <ul>
                <li><strong>GET /category/get</strong> - Fetches all categories</li>
                <ul>
                <li>Returns: <code>{'[{'} CategoryName {'}]'}</code></li>
                </ul>

                <br/>
                <br/>

                <li><strong>POST /category/create</strong> - Creates a new category</li>
                <ul>
                <li>Required Headers: <code>name</code>, <code>token</code></li>
                <li>Returns: <code>"Category Added"</code></li>
                </ul>

                <br/>
                <br/>

                <li><strong>POST /category/delete</strong> - Deletes a category</li>
                <ul>
                <li>Required Headers: <code>name</code>, <code>token</code></li>
                <li>Returns: <code>"Category Removed"</code></li>
                </ul>
            </ul>
            </div>

            <br/>
            <br/>

            <div className="api-section">
            <h2>Loans API</h2>
            <ul>
                <li><strong>GET /loans/get</strong> - Fetches all loans</li>
                <ul>
                <li>Returns: <code>{'[{'} LoanID, Principle, AmountOwe, Discount {'}]'}</code></li>
                </ul>

                <br/>
                <br/>

                <li><strong>POST /loans/claim</strong> - Claims a loan</li>
                <ul>
                <li>Required Headers: <code>username</code>, <code>loanID</code></li>
                <li>Returns: Updated user data with loan claimed</li>
                </ul>

                <br/>
                <br/>

                <li><strong>POST /loans/pay</strong> - Pays off a loan</li>
                <ul>
                <li>Required Headers: <code>username</code>, <code>amount</code></li>
                <li>Returns: Updated user data with loan paid off</li>
                </ul>
            </ul>
            </div>

            <br/>
            <br/>

            <div className="api-section">
            <h2>Stats API</h2>
            <ul>
                <li><strong>GET /stats</strong> - Fetches user statistics</li>
                <ul>
                <li>Returns: <code>{'{'} MostSpent, MostLost, MostWon, MostEarnings, HighestWin {'}'}</code></li>
                </ul>
            </ul>
            </div>
            </>
        )
    }
    else
    {
        return null;
    }
};