import "./leaderboard.css";
import { StatisticsContainer, GetStats, UserStatistics} from "./objects/StatsGet";
import {User} from "./objects/UserLogin";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


interface LeaderboardProps {
    user: User | null;
}



const Leaderboard: React.FC<LeaderboardProps> = ({ user}) => {
    const [activeTab, setActiveTab] = useState('net-earnings');
    const [netEarningsList, setNetEarningsList] = useState<UserStatistics[]>([]);
    const [totalSpentList, setTotalSpentList] = useState<UserStatistics[]>([]);
    const [totalLostList, setTotalLostList] = useState<UserStatistics[]>([]);
    const [totalWonList, setTotalWonList] = useState<UserStatistics[]>([]);
    const [highestWinList, setHighestWinList] = useState<UserStatistics[]>([]);

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };
    
    
    useEffect(() => {
        const fetchLeaderboardData = async () => {
            try {
                
                const response = await GetStats();
                const data: StatisticsContainer = await response.json();

                setNetEarningsList(data.MostEarnings);
                setHighestWinList(data.HighestWin);
                setTotalLostList(data.MostLost);
                setTotalSpentList(data.MostSpent);
                setTotalWonList(data.MostWon);

            } catch (error) {
                console.error('Error fetching leaderboard data:', error);
            }
        };

        fetchLeaderboardData();
    }, []);


    return(
        <div className="account-body">
            <div className="account-container">
                <div className="account-form">
                    <div className="account-card">
                        <h1>Leaderboards</h1>
                        <br/>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <Link
                                className={`nav-link ${activeTab === 'net-earnings' ? 'active' : ''}`}
                                onClick={() => handleTabClick('net-earnings')}
                                to="#"
                                style={{ color: activeTab === 'net-earnings' ? 'black' : 'green' }}
                                >
                                Total Net Earnings
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                className={`nav-link ${activeTab === 'total-spent' ? 'active' : ''}`}
                                onClick={() => handleTabClick('total-spent')}
                                to="#"
                                style={{ color: activeTab === 'total-spent' ? 'black' : 'green' }}
                                >
                                Total Spent
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                className={`nav-link ${activeTab === 'total-lost' ? 'active' : ''}`}
                                onClick={() => handleTabClick('total-lost')}
                                to="#"
                                style={{ color: activeTab === 'total-lost' ? 'black' : 'green' }}
                                >
                                Total Lost
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                className={`nav-link ${activeTab === 'total-won' ? 'active' : ''}`}
                                onClick={() => handleTabClick('total-won')}
                                to="#"
                                style={{ color: activeTab === 'total-won' ? 'black' : 'green' }}
                                >
                                Total Won
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                className={`nav-link ${activeTab === 'highest-win' ? 'active' : ''}`}
                                onClick={() => handleTabClick('highest-win')}
                                to="#"
                                style={{ color: activeTab === 'highest-win' ? 'black' : 'green' }}
                                >
                                Highest Win
                                </Link>
                            </li>
                        </ul>

                        <div id="tab-content">
                            {activeTab === 'net-earnings' && (
                                <div id="net-earnings-content">
                                    <table>
                                        <tr>
                                            <td>{user?.Username}: </td>
                                            <td>${(((user?.Balance ?? 0) - 100) - (user?.Debt ?? 0))?.toFixed(2)}</td>
                                        </tr>
                                    </table>
                                    <br/>
                                    <div style={{ maxHeight: '400px', overflowY: 'scroll', border: '2px solid black' }}>
                                        <table className="leaderboard-table" id="net-earnings-table">
                                            {netEarningsList.length === 0 && 
                                                (<div className="spinner-border text-success" role="status">
                                                    <span className="sr-only"></span>
                                                </div>)
                                            }
                                            {netEarningsList.map((user, index) => (
                                                <tr style={{ borderBottom: '1px solid black' }}>
                                                    <td className="leaderboard-number">{index + 1}</td>
                                                    <td style={{width: '30%'}}><Link className="leaderboard-a" to={`/player?name=${user?.Username}`}>{user?.Username}</Link></td>
                                                    <td style={{width: '60%'}}>${user?.TotalEarnings?.toFixed(2)}</td>
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                </div>
                            )}
                            {activeTab === 'total-spent' && (
                                <div id="total-spent-content">
                                    <table>
                                        <tr>
                                            <td>{user?.Username}: </td>
                                            <td>${(user?.TotalSpent?.toFixed(2))}</td>
                                        </tr>
                                    </table>
                                    <br/>
                                    <div style={{ maxHeight: '400px', overflowY: 'scroll', border: '2px solid black' }}>
                                        <table className="leaderboard-table" id="total-spent-table">
                                            {totalSpentList.length === 0 && 
                                                (<div className="spinner-border text-success" role="status">
                                                    <span className="sr-only"></span>
                                                </div>)
                                            }
                                            {totalSpentList.map((user, index) => (
                                                <tr style={{ borderBottom: '1px solid black' }}>
                                                    <td className="leaderboard-number">{index + 1}</td>
                                                    <td style={{width: '30%'}}><Link className="leaderboard-a" to={`/player?name=${user?.Username}`}>{user?.Username}</Link></td>
                                                    <td style={{width: '60%'}}>${user?.TotalSpent?.toFixed(2)}</td>
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                </div>
                            )}
                            {activeTab === 'total-lost' && (
                                <div id="total-lost-content">
                                    <table>
                                        <tr>
                                            <td>{user?.Username}: </td>
                                            <td>${((user?.TotalSpent ?? 0) - (user?.TotalWon ?? 0))?.toFixed(2)}</td>
                                        </tr>
                                    </table>
                                    <br/>
                                    <div style={{ maxHeight: '400px', overflowY: 'scroll', border: '2px solid black' }}>
                                        <table className="leaderboard-table" id="total-lost-table">
                                            {totalLostList.length === 0 && 
                                                (<div className="spinner-border text-success" role="status">
                                                    <span className="sr-only"></span>
                                                </div>)
                                            }
                                            {totalLostList.map((user, index) => (
                                                <tr style={{ borderBottom: '1px solid black' }}>
                                                    <td className="leaderboard-number">{index + 1}</td>
                                                    <td style={{width: '30%'}}><Link className="leaderboard-a" to={`/player?name=${user?.Username}`}>{user?.Username}</Link></td>
                                                    <td style={{width: '60%'}}>${user?.TotalLost?.toFixed(2)}</td>
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                </div>
                            )}
                            {activeTab === 'total-won' && (
                                <div id="total-won-content">
                                    <table>
                                        <tr>
                                            <td>{user?.Username}: </td>
                                            <td>${(user?.TotalWon ?? 0)?.toFixed(2)}</td>
                                        </tr>
                                    </table>
                                    <br/>
                                    <div style={{ maxHeight: '400px', overflowY: 'scroll', border: '2px solid black' }}>
                                        <table className="leaderboard-table" id="total-won-table">
                                            {totalWonList.length === 0 && 
                                                (<div className="spinner-border text-success" role="status">
                                                    <span className="sr-only"></span>
                                                </div>)
                                            }
                                            {totalWonList.map((user, index) => (
                                                <tr style={{ borderBottom: '1px solid black' }}>
                                                    <td className="leaderboard-number">{index + 1}</td>
                                                    <td style={{width: '30%'}}><Link className="leaderboard-a" to={`/player?name=${user?.Username}`}>{user?.Username}</Link></td>
                                                    <td style={{width: '60%'}}>${user?.TotalWon?.toFixed(2)}</td>
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                </div>
                            )}
                            {activeTab === 'highest-win' && (
                                <div id="highest-win-content">
                                    <table>
                                        <tr>
                                            <td>{user?.Username}: </td>
                                            <td>${(user?.HighestWin ?? 0)?.toFixed(2)}</td>
                                        </tr>
                                    </table>
                                    <br/>
                                    <div style={{ maxHeight: '400px', overflowY: 'scroll', border: '2px solid black' }}>
                                        <table className="leaderboard-table" id="highest-win-table">
                                            {highestWinList.length === 0 && 
                                                (<div className="spinner-border text-success" role="status">
                                                    <span className="sr-only"></span>
                                                </div>)
                                            }
                                            {highestWinList.map((user, index) => (
                                                <tr style={{ borderBottom: '1px solid black' }}>
                                                    <td className="leaderboard-number">{index + 1}</td>
                                                    <td style={{width: '30%'}}><Link className="leaderboard-a" to={`/player?${user?.Username}`}>{user?.Username}</Link></td>
                                                    <td style={{width: '60%'}}>${user?.HighestWin?.toFixed(2)}</td>
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Leaderboard;