export interface UserStatistics {
    Username?: string;
    TotalSpent?: number;
    TotalLost?: number;
    TotalWon?: number;
    TotalEarnings?: number;
    HighestWin?: number;
}

export interface StatisticsContainer {
    MostSpent: UserStatistics[];
    MostLost: UserStatistics[];
    MostWon: UserStatistics[];
    MostEarnings: UserStatistics[];
    HighestWin: UserStatistics[];
}


export async function GetStats()
{
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    return await fetch('https://feedmyaddiction-db.dallasagsmith.workers.dev/stats', {
        method: 'GET', 
        headers: headers,
    });
}