import { GetUsers, UserGet_User } from "./objects/UserGet";
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";


function PlayerSearch() {
    const [searchList, setSearchList] = useState<UserGet_User[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    

    
    useEffect(() => {
        const fetchUsers = async () => {
            if(searchValue !== '')
            {
                setLoading(true);
                try {
                    const response = await GetUsers();
                    const data: UserGet_User[] = await response.json();
                    const usersList: UserGet_User[] = [];
    
                    for (let i = 0; i < data.length; i++)
                    {
                        if (data[i].Username.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
                        {
                            usersList.push(data[i]);
                        }
                    }
    
                    setSearchList(usersList);
                } catch (error) {
                    console.error('Error fetching leaderboard data:', error);
                }
                setLoading(false);
            }
        };

        fetchUsers();
    }, [searchValue]);


    function searchPlayers() {
        setSearchValue((document.getElementById('searchResult') as HTMLInputElement)?.value ?? '');
    }

    return(
        <div className="account-body">
            <div className="account-container">
                <div className="account-form">
                    <div className="account-card" style={{width: '25%'}}>
                        <h1>Players</h1>
                        <br/>
                        <div className="d-flex align-items-center mb-3">
                            <input className="form-control me-2" type="search" id="searchResult" placeholder="Search Players" aria-label="Search"/>
                            <button className="btn btn-success" type="submit" onClick={searchPlayers}>Search</button>
                        </div>
                        <br/>
                        {loading === true 
                            ? 
                            (<div className="spinner-border text-success" role="status">
                                <span className="sr-only"></span>
                            </div>) 
                            :
                            (
                                <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                                    <table className="leaderboard-table" id="net-earnings-table">
                                        {searchList.length === 0 ? 
                                            (<p>No players found.</p>)
                                            :
                                            (<p>{searchList.length} results</p>)
                                        }
                                        {searchList.map((user, index) => (
                                            <tr style={{ borderBottom: '1px solid black' }}>
                                                <td style={{width: '30%'}}><Link className="leaderboard-a" to={`/player?name=${user?.Username}`}>{user?.Username}</Link></td>
                                            </tr>
                                        ))}
                                    </table>
                                </div>
                            )
                        }
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default PlayerSearch;