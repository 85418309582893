import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from "./header";
import Welcome from './Welcome';
import React, { useCallback, useEffect, useState } from 'react';
import Login from './login';
import Register from './register';
import Account from './Account';
import {User, GetUserType} from "./objects/UserLogin";
import Leaderboard from './leaderboard';
import Player from './player';
import PlayerSearch from './playerSearch';
import Loans from './loans';
import GamePlayer from './game';
import AllGames from './allGames';
import Slots from './slots';
import Tables from './table';
import AdminPage from './admin';

function App(){
    const [user, setUser] = useState<User | null>(null);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    // // Callback function to update the user state
    // const handleUserLogin = (userData: User) => {
    //     if (userData === null)
    //     {
    //         console.log("seting User to Null");
    //     }
    //     else
    //     {
    //         console.log("seting User");
    //     }
    //     setUser(userData);
    // };

    const handleUserLogin = useCallback((newUser: User) => {
        if(newUser !== null)
        {
            const userString = JSON.stringify(newUser);
            sessionStorage.setItem('user', userString);
        }
        else
        {
            sessionStorage.clear();
        }
        setUser(newUser);
    }, []); 



    useEffect(()  => {
        async function RunEffect() {
            const userString = sessionStorage.getItem('user');
        
            if (userString) {
                const user: User = JSON.parse(userString);
                setUser(user);
                const response = await GetUserType(user.Token ?? '');
                const data = await response.text();
                if (data === 'Admin')
                {
                    setIsAdmin(true);
                }
            }
            else
            {
                setUser(null);
            }
        }

        RunEffect();
    }, []);

    return (
        <Router>
            <Header user={user} onLogin={handleUserLogin}/>
            <Routes>
              <Route path="/" element={<Welcome />} />
              <Route path="/login" element={user !== null ? <Welcome/> : <Login onLogin={handleUserLogin}/>} />
              <Route path="/register" element={user !== null ? <Welcome/> : <Register/>} />
              <Route path="/player/search" element={user === null ? <Login onLogin={handleUserLogin}/> : <PlayerSearch/>} />
              <Route path="/player" element={user === null ? <Login onLogin={handleUserLogin}/> : <Player/>} />
              <Route path="/leaderboards" element={user === null ? <Login onLogin={handleUserLogin}/> : <Leaderboard user={user}/>} />
              <Route path="/account" element={user === null ? <Login onLogin={handleUserLogin}/> : <Account user={user} onLogin={handleUserLogin}/>} />
              <Route path="/loans" element={user === null ? <Login onLogin={handleUserLogin}/> : <Loans user={user} onLogin={handleUserLogin}/>} />
              <Route path="/game" element={user === null ? <Login onLogin={handleUserLogin}/> : <GamePlayer user={user}/>} />
              <Route path="/game/all" element={user === null ? <Login onLogin={handleUserLogin}/> : <AllGames user={user}/>} />
              <Route path="/game/slots" element={user === null ? <Login onLogin={handleUserLogin}/> : <Slots user={user}/>} />
              <Route path="/game/tables" element={user === null ? <Login onLogin={handleUserLogin}/> : <Tables user={user}/>} />
              <Route path="/admin" element={isAdmin === false ? <Welcome/> : <AdminPage user={user}/>} />
              {/* <Footer/> */}
            </Routes>
        </Router>
    );
}

export default App;