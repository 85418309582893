import "./Account.css";
import { useEffect, useState } from "react";
import {User} from "./objects/UserLogin";
import { Link } from 'react-router-dom';
import { Loan, GetLoans, ClaimLoan, PayLoan } from "./objects/LoansGet";
import bankLogo from './images/fmabank.png';

interface LoansProps {
    user: User | null;
    onLogin: (userData: any) => void;
}



const Loans: React.FC<LoansProps> = ({ user, onLogin }) => {
    const calculateTimeLeft = () => {
        const now = new Date(); // Current local time
        const nowUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()));
        
        // Set midnight UTC of the next day
        const midnightUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1, 0, 0, 0));
        
        // Calculate the difference in milliseconds
        const difference = midnightUTC.getTime() - nowUTC.getTime();
        // Convert milliseconds to seconds
        const seconds = Math.floor(difference / 1000);
        return seconds;
    };

    const [loan, setLoan] = useState<Loan | null>(null);
    const [tabNav, setTabNav] = useState<string>('claim');
    const [timer, setTimer] = useState<number>(calculateTimeLeft());
    const [loanList, setLoanList] = useState<Loan[]>([]);
    const [agree, setAgree] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(false);

    const [payment, setPayment] = useState<boolean>(false);
    const [paymentText, setPaymentText] = useState<string>('Processing...');
    const [paymentFinishedText, setPaymentFinishedText] = useState<string>('Approved');
    const [paymnetSpinner, setPaymentSpinner] = useState<boolean>(true);
    
    const [topCheck, setTopCheck] = useState<boolean>(true);
    const [bottomCheck, setBottomCheck] = useState<boolean>(false);
    const [payAmount, setPayAmount] = useState<number>(0);
    const [displayAmount, setDisplayAmount] = useState<number>(0);




    const handleTabClick = (tab: string) => {
        setTabNav(tab);
    };

    const formatTime = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    useEffect(() => {
        const fetchLeaderboardData = async () => {
            try {
                
                const response = await GetLoans();
                const data: Loan[] = await response.json();

                setLoanList(data);

            } catch (error) {
                console.error('Error fetching loan data:', error);
            }
        };
            fetchLeaderboardData();
    }, [])

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                const newTime = prevTimer - 1;
                if (newTime <= 0) {
                    clearInterval(interval!);
                    return 0;
                }
                return newTime;
                });
            }, 1000); // Decrease the timer by 1 every second
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };

    }, [timer]);
    
    
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked)
        {
            setIsChecked(true);
            setAgree(true);
        }
        else
        {
            setIsChecked(false);
            setAgree(false);
        }
    };
    
    
    async function handleLoanSubmit(){
        const response = await ClaimLoan(user?.Username ?? '', loan?.ID ?? 0);

        const contentType = response.headers.get('Content-Type');
    
        if (contentType && contentType.includes('application/json')) {
            const data: User = await response.json();
            const userString = JSON.stringify(data);
            sessionStorage.setItem('user', userString);
            onLogin(data);
        }
        else
        {
            const data = await response.text();
            setPaymentFinishedText(data);
        }
        
        setDisplayAmount(loan?.Principle ?? 0);
        setIsChecked(false);
        setAgree(false);
        setPayment(true);
    }

    useEffect(() => {
        const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
    
        const processPayment = async (text: string) => {
          if (payment) {
            setPaymentSpinner(true);
            setPaymentText("Processing...");
            await delay(2000);
            setPaymentText("Authorizing...");
            await delay(2000);
            setPaymentText(text);
            setPaymentSpinner(false);
            await delay(2000);
            setPayment(false);
            setLoan(null);
          }
        };
    
        processPayment(paymentFinishedText);
    }, [payment, paymentFinishedText]);

    const handleTopClick = () => {
        setTopCheck(true);
        setBottomCheck(false);
    };

    const handleBottomClick = () => {
        setTopCheck(false);
        setBottomCheck(true);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPayAmount(Number(e.target.value));
    };

    async function handleDebtSubmit(){
        const amount = topCheck ? user?.Debt : Number(payAmount.toFixed(2));
        setDisplayAmount(amount ?? 0);
        const response = await PayLoan(user?.Username ?? '', amount ?? 0);
        if (response.ok) {
            const data: User = await response.json();
            const userString = JSON.stringify(data);
            sessionStorage.setItem('user', userString);
            onLogin(data);
            setPayAmount(0);
            setPaymentFinishedText("Approved");
            setPayment(true);
        } else {
            const errorText = await response.text();
            setPaymentFinishedText(errorText);
            setPayment(true);
        }
    }


    return(
        <>
        {payment ?
            <div className="account-body">'
                <div className="account-form" style={{color: 'white'}}>
                    <img src={bankLogo} width={300} height={300} alt="Bank Logo"/>
                    {paymnetSpinner && <h3>${displayAmount}</h3>}
                    <h2>{paymentText}</h2>
                    {paymnetSpinner && 
                        (<div className="spinner-border text-success spinner-border-lg" role="status">
                            <span className="sr-only"></span>
                        </div>)}
                </div>
            </div>
            :
            <>
            {loan === null ?
                <>
                    <div className="account-body">
                        <div className="account-form" style={{color: 'white'}}>
                            <br/>
                            <img src={bankLogo} width={250} height={250} alt="Bank Logo"/>
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <Link
                                    className={`nav-link ${tabNav === 'claim' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('claim')}
                                    to="#"
                                    style={{ color: tabNav === 'claim' ? 'black' : 'green' }}
                                    >
                                    Recieve a loan
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                    className={`nav-link ${tabNav === 'pay' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('pay')}
                                    to="#"
                                    style={{ color: tabNav === 'pay' ? 'black' : 'green' }}
                                    >
                                    Pay off loans
                                    </Link>
                                </li>
                            </ul>
                            <br/>
                            <div id="tab-content">
                                {tabNav === 'claim' && (
                                    <div id="claim-content">
                                        <h1>Available Loans</h1>
                                        {loanList.length === 0 && 
                                            (<div className="spinner-border text-success" role="status">
                                                <span className="sr-only"></span>
                                            </div>)
                                        }
                                        <div className="list-group" style={{ maxHeight: '400px', width:'400px', overflowY: 'scroll', borderTop: '1px solid black', borderBottom: '2px solid black' }}>
                                            {loanList.map((loan, index) => (
                                                <>
                                                <Link to={""} className="list-group-item list-group-item-action flex-column align-items-start" style={{marginTop: 5}}
                                                    onClick={(e) => {setLoan(loan)}}
                                                >
                                                    <div className="d-flex w-100 justify-content-between">
                                                    <h5 className="mb-1">${loan.Principle}</h5>
                                                    {loan.Discount !== 0 && <small className="text-muted">Sale ends in: {formatTime(timer)}</small>}
                                                    </div>
                                                    {loan.Discount === 0 ?
                                                        (<p className="mb-1">Must return ${loan.AmountOwe}</p>)
                                                        :
                                                        (<p className="mb-1">Must return <span style={{textDecoration: 'line-through'}}>${loan.AmountOwe}</span> <span style={{color: 'red'}}>${loan.AmountOwe - Number((loan.AmountOwe * loan.Discount).toFixed(2))}</span></p>)
                                                    }
                                                    <small className="text-muted">{loan.ID}</small>
                                                </Link>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {tabNav === 'pay' && (
                                    <div id="pay-content">
                                        <section style={{backgroundColor: "transparent"}}>
                                            <div className="container py-5">
                                                <div className="row d-flex justify-content-center">
                                                    <div className="col-md-8 col-lg-6 col-xl-4">
                                                        <div className="card rounded-3" style={{backgroundColor: 'lightgrey'}}>
                                                            <div className="card-body mx-1 my-2" style={{backgroundColor: 'lightgrey'}}>
                                                                <div className="d-flex align-items-center" style={{width: 1000}}>
                                                                    <div>
                                                                        <img src={bankLogo} width={100} height={100} alt="Bank Logo"/>
                                                                    </div>
                                                                    <div>
                                                                        <p className="d-flex flex-column mb-0">
                                                                            <b>{user?.Username}</b><span className="small text-muted">**** (${user?.Balance?.toFixed(2)})</span>
                                                                        </p>
                                                                    </div>
                                                                    </div>

                                                                    <div className="pt-3">
                                                                        <div className="d-flex flex-row pb-3">
                                                                            <div className={`${topCheck ? 'rounded border border-success border-2 d-flex w-100 p-3 align-items-center' : 'rounded border d-flex w-100 px-3 py-2 align-items-center'}`} onClick={handleTopClick}>
                                                                                <div className="d-flex flex-column">
                                                                                    <p className="mb-1 small text-success">Total amount due</p>
                                                                                    <h6 className="mb-0 text-success">${user?.Debt?.toFixed(2)}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="d-flex flex-row pb-3">
                                                                            <div className={`${bottomCheck ? 'rounded border border-success border-2 d-flex w-100 p-3 align-items-center' : 'rounded border d-flex w-100 px-3 py-2 align-items-center'}`} onClick={handleBottomClick}>
                                                                                <div className="d-flex flex-column py-1">
                                                                                    <p className="mb-1 small text-success">Other amount</p>
                                                                                    <div className="d-flex flex-row align-items-center">
                                                                                    <h6 className="mb-0 text-success pe-1">$</h6>
                                                                                    <input type="number" className="form-control form-control-sm" id="payAmount" min={0}
                                                                                        style={{width: "100px"}} value={payAmount} onChange={handleInputChange}/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex justify-content-between align-items-center pb-1">
                                                                    <button  type="button" data-mdb-button-init data-mdb-ripple-init className="btn btn-success btn-lg" onClick={handleDebtSubmit}>Pay amount</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
                :
                <div className="account-body">
                    <div className="account-container">
                        <div className="account-form">
                            <div className="account-card">
                                <h1>Loan Details</h1>
                                <table style={{width: '100%'}}>
                                    <tr>
                                        <td className="account-label">Receive: </td>
                                        <td className="account-label" style={{paddingLeft: 15}}>${loan.Principle}</td>
                                    </tr>
                                    <tr>
                                        <td className="account-label">Owe: </td>
                                        {loan.Discount === 0 ? 
                                            <td className="account-label" style={{paddingLeft: 15}}>${loan.AmountOwe}</td>
                                            :
                                            <td className="account-label" style={{paddingLeft: 15}}>${loan.AmountOwe - Number((loan.AmountOwe * loan.Discount).toFixed(2))}</td>
                                        }
                                    </tr>
                                    {loan.Discount !== 0 && <tr><td className="account-label">Discount: </td><td className="account-label" style={{paddingLeft: 15}}>${Number((loan.AmountOwe * loan.Discount).toFixed(2))} ({Number((loan.Discount * 100).toFixed(0))}%)</td></tr>}
                                    <br/>
                                </table>
                                <div style={{ maxHeight: '300px', overflowY: 'scroll', border: '2px solid black' }}>
                                    <p style={{width: 350}}>Agreement Terms

                                    By checking the box below, you are agreeing to the following totally serious (and definitely not legally binding) agreement:

                                    Debt Responsibility: You acknowledge that you must pay off your debts. Not just your debts, but all debts ever recorded in the universe. If you don't, well, let's just say the consequences are... imaginative.

                                    Punishments:

                                    Mugged: You may find yourself humorously mugged by a gang of unpaid actors who will demand your lunch money and possibly a photo op for their Instagram stories.
                                    Hacked: Prepare to have your most embarrassing photos sent to your entire contact list. We're talking prom photos with glitter and bad haircuts.
                                    Tickled to Death: If you default, our team of professional ticklers will come to your house armed with feathers and a mission to make you laugh until you can't breathe (and possibly fall off your chair).
                                    Demoted to Silver: Every game you play will see you demoted to silver rank, even if you were previously the undisputed champion of the universe. No more bragging rights for you—just endless frustration and silver medals.
                                    Unpleasant Surprises:

                                    Stuck in an Endless Loop: Your favorite social media feed will be forever stuck in an endless loop of motivational quotes from 90's celebrities.
                                    Rubbish Superpowers: You'll be granted superpowers like the ability to change TV channels with your mind but only to channels showing soap operas.
                                    Endless Jokes: You'll receive an infinite number of dad jokes every day until your debt is settled. Remember, laughter is the best medicine, but it won't pay off your debt.
                                    Legal Notice: This agreement is for a fictional loan in a completely imaginary game. No actual mugging, hacking, or tickling will occur. But we do promise that playing our game will make you laugh, cry, and question your life choices.

                                    By checking the box, you agree to embrace the chaos and enjoy the ride. Happy gaming!</p>
                                </div>
                                <input type="checkbox" id="agree" name="agree" value="agree" checked={isChecked} onChange={handleCheckboxChange}/>
                                <label htmlFor="agree" style={{paddingLeft: 5}}>I Agree</label>
                                <br/>
                                <br/>
                                <button className="btn btn-secondary" type="submit" onClick={(e) => {setLoan(null)}}>Cancel</button>
                                <button className={`btn btn-success ${agree ? '' : 'disabled'}`} type="submit" style={{marginLeft: 5}} onClick={handleLoanSubmit}>Receive Loan</button>
                                <br/>
                                <br/>
                                <p>Must have less than $75,000 debt to claim.</p>
                            </div>
                        </div>
                    </div>
                </div>   
            }
            </>
        }
        </>
    );
}

export default Loans;