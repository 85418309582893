import "./game.css";
import {User} from "./objects/UserLogin";
import {Game, GetGame} from "./objects/GameGet";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

interface GamePlayerProps {
    user: User | null;
}

const GamePlayer: React.FC<GamePlayerProps> = ({ user }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const gameID = queryParams.get('id');
    const [currentGame, setCurrentGame] = useState<Game | null>(null);
    const navigate = useNavigate();

    if (typeof Number(gameID) !== 'number' || gameID === null)
    {
        navigate("/");
    }

    useEffect(() => {
        const fetchGame = async () => {
            try {
                
                const response = await GetGame();
                const data: Game[] = await response.json();
                
                for (let i = 0; i < data.length; i++)
                {
                    if (data[i].ID === Number(gameID))
                    {
                        setCurrentGame(data[i]);
                    }
                }
    
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
    
        fetchGame();
    }, [gameID]);

    const URL = `https://feedmyaddiction-db.dallasagsmith.workers.dev/games/load?username=${user?.Username}&gameid=${gameID}&file=index&type=html`;
    const descriptionURL = `https://feedmyaddiction-db.dallasagsmith.workers.dev/games/load?username=${user?.Username}&gameid=${gameID}&file=description&type=html`;

    return(
        <div className="account-body">
            <div className="account-container">
                <div className="account-form">
                    <br/>
                    <br/>
                    <iframe title="game-screen" src={URL} style={{borderWidth: 2, borderStyle: "solid", borderColor: 'black', backgroundColor:'black', height: '60vh', width: '55vw', minWidth: '800px', minHeight: '400px'}}></iframe>
                    <h3>Title: {currentGame?.Name}</h3>
                    <table style={{borderStyle: 'groove', borderWidth: 3, borderColor: 'black', width: '30%', opacity: 0.6}}>
                        <tbody>
                            <tr>
                                <td colSpan={3}>Highest Win Leaderboard</td>
                            </tr>
                            <tr >
                                <td style={{borderStyle: 'groove', borderWidth: 3, borderColor: 'black'}}>1</td>
                                <td style={{borderStyle: 'groove', borderWidth: 3, borderColor: 'black', width: '40%'}}><Link className="leaderboard-a" to={`/player?name=${currentGame?.FirstName}`}>{currentGame?.FirstName}</Link></td>
                                <td style={{borderStyle: 'groove', borderWidth: 3, borderColor: 'black', width: '40%'}}>${currentGame?.FirstWin}</td>
                            </tr>
                            <tr>
                                <td style={{borderStyle: 'groove', borderWidth: 3, borderColor: 'black'}}>2</td>
                                <td style={{borderStyle: 'groove', borderWidth: 3, borderColor: 'black', width: '40%'}}><Link className="leaderboard-a" to={`/player?name=${currentGame?.SecondName}`}>{currentGame?.SecondName}</Link></td>
                                <td style={{borderStyle: 'groove', borderWidth: 3, borderColor: 'black', width: '40%'}}>${currentGame?.SecondWin}</td>
                            </tr>
                            <tr>
                                <td style={{borderStyle: 'groove', borderWidth: 3, borderColor: 'black'}}>3</td>
                                <td style={{borderStyle: 'groove', borderWidth: 3, borderColor: 'black', width: '40%'}}><Link className="leaderboard-a" to={`/player?name=${currentGame?.ThirdName}`}>{currentGame?.ThirdName}</Link></td>
                                <td style={{borderStyle: 'groove', borderWidth: 3, borderColor: 'black', width: '40%'}}>${currentGame?.ThirdWin}</td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <iframe title="description-box" height={600} width={1000} src={descriptionURL} style={{borderWidth: 3, borderStyle: "groove", borderColor: 'black', height: '60vh', width: '55vw', minWidth: '400', minHeight: '200'}}></iframe>
                </div>
            </div>
        </div>
    );
}

export default GamePlayer;
