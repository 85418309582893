import { useState } from "react";
import "./login.css";
import {Link, useNavigate } from 'react-router-dom';
import {RegisterUser} from "./objects/APIRequests";

function Register() {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        setMessage("");
        
        const username = (document.getElementById("inputUsername") as HTMLInputElement | null)?.value;
        const email = (document.getElementById("inputEmail") as HTMLInputElement | null)?.value;
        const password = (document.getElementById("inputPassword") as HTMLInputElement | null)?.value;
        const confpassword = (document.getElementById("inputConfirmPassword") as HTMLInputElement | null)?.value;

        try
        {
            if (confpassword !== password)
            {
                setMessage("Passwords do not match.");
            }
            else
            {
                let response = await RegisterUser(username ?? "", password ?? "", email?? "");
    
                if (!response.ok) {
                    setMessage((await response.text()).toString());
                }
                else
                {
                    navigate("/login");
                }
            }
        }
        catch
        {
            setMessage("Error contacting server.");
        }

        setIsLoading(false);
    }


    return (
        <div className="login-body">
            <div className="login-container">
                <div className="login-form">
                    <h1>Sign Up</h1>
                    <form className="form-signin" onSubmit={handleSubmit}>
                        <input type="email" id="inputEmail" className="form-control" placeholder="Email" required />
                        <br/>
                        <input type="text" id="inputUsername" className="form-control" placeholder="Username" required />
                        <br/>
                        <input type="password" id="inputPassword" className="form-control" placeholder="Password" required/>
                        <br/>
                        <input type="password" id="inputConfirmPassword" className="form-control" placeholder="Confirm Password" required/>
                        <br/>
                        <button className="btn btn-lg btn-success btn-block" type="submit">Sign up</button>
                        <br/>
                        <p className="login-error">{message}</p>
                        <br/>
                        <Loading loading={isLoading}/>
                        <br/>
                        <Link to="/login">Already have an account? Sign in</Link>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Register;



interface LoadingProps {
    loading: boolean;
}
  
const Loading: React.FC<LoadingProps> = ({ loading }) => {

    if (loading === true)
    {
        return (
        <div className="spinner-border text-success" role="status">
            <span className="sr-only"></span>
        </div>
        );
    }
    else
    {
        return(
        <></>
    );
    }
}