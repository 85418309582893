import { Link, useLocation } from 'react-router-dom';
import {User, GetUserType} from "./objects/UserLogin";
import { useEffect, useState } from 'react';
import { GetUsers, UserGet_User } from './objects/UserGet';

interface HeaderProps {
  user: User | null;
  onLogin: (userData: any) => void;
}

const Header: React.FC<HeaderProps> = ({ user, onLogin }) => {
  const location = useLocation();

  useEffect(() => {
    const handleUrlChange = async () => {
      const response = await GetUsers();
      const data: UserGet_User[] = await response.json();

      for (let i = 0; i < data.length; i++) {
        if (data[i].Username === user?.Username) {
          const newUser: User = {
            Username: user.Username,
            Balance: data[i].Balance ?? user.Balance,
            TotalSpent: data[i].TotalSpent ?? user.TotalSpent,
            TotalWon: data[i].TotalWon ?? user.TotalWon,
            Debt: data[i].Debt ?? user.Debt,
            HighestWin: data[i].HighestWin ?? user.HighestWin,
            Email: user.Email,
            Token: user.Token,
          };

          // Check if the data has actually changed before calling onLogin
          if (
            newUser.Balance !== user.Balance ||
            newUser.TotalSpent !== user.TotalSpent ||
            newUser.TotalWon !== user.TotalWon ||
            newUser.Debt !== user.Debt ||
            newUser.HighestWin !== user.HighestWin
          ) {
            // add session too
            onLogin(newUser);
          }

          break;
        }
      }
    };

    handleUrlChange();
  }, [
    location,
    user?.Balance,
    user?.Debt,
    user?.Email,
    user?.HighestWin,
    user?.Token,
    user?.TotalSpent,
    user?.TotalWon,
    user?.Username,
    onLogin,
  ]);

    return (
        <nav className="navbar navbar-dark bg-dark fixed-top">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">Feed My Addiction</Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="offcanvas offcanvas-end text-bg-dark" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">Navigate</h5>
              <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
              <NavValues user={user}/>
            </div>
          </div>
        </div>
      </nav>
    );
  }
  
export default Header;


interface NavValuesProps {
  user: User | null;
}

const NavValues: React.FC<NavValuesProps>  = ({ user })  => {
  if (user == null)
  {
    return (
      <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
        <li className="nav-item">
          <Link className="nav-link" to="/">Welcome</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/login">Login</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/register">Sign Up</Link>
        </li>
      </ul>
    )
  }
  else
  {
    return(
      <>
      <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
        <li className="nav-item" style={{textAlign: "right", color: "darkgray"}}>
          <p>Balance: ${user.Balance?.toFixed(2)}</p>
        </li>
        <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/">Welcome</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/game/slots">Slots</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/game/tables">Tables</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/game/all">All Games</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/loans">Loans</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/player/search">Player Search</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/leaderboards">Leaderboards</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/account">Account Details</Link>
        </li>
        <Admin user={user}/>
      </ul>
      <form className="d-flex mt-3" role="search">
        <input className="form-control me-2" type="search" placeholder="Search Games" aria-label="Search"/>
        <button className="btn btn-success" type="submit">Search</button>
      </form>
      </>);
  }
}

interface AdminProps {
  user: User | null;
}

const Admin: React.FC<AdminProps> = ({ user }) => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    const fetchUserType = async () => {
      if (user?.Token) {
        try {
          const response = await GetUserType(user.Token);
          const data: string = await response.text();
          
          if (data === 'Admin') {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        } catch (error) {
          console.error('Failed to fetch user type:', error);
          setIsAdmin(false);
        }
      }
    };

    fetchUserType();
  }, [user]);

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <br />
      <li className="nav-item">
        <Link className="nav-link" to="/admin">Admin</Link>
      </li>
    </>
  );
};