import "./Account.css";
import { useState } from "react";
import {User} from "./objects/UserLogin";
import { useNavigate } from 'react-router-dom';
import profileImage from './images/profile.png';
import {UpdatePasswordRequest, ResetUser} from './objects/APIRequests';

interface AccountProps {
    user: User | null;
    onLogin: (userData: any) => void;
}



const Account: React.FC<AccountProps> = ({ user, onLogin }) => {
    const navigate = useNavigate();

    function Logout(){
        sessionStorage.clear();
        onLogin(null);
        navigate("/login");
    }

    return(
        <div className="account-body">
            <div className="account-container">
                <div className="account-form">
                    <div className="account-card">
                        <h1>Account Details</h1>
                        <table>
                            <tr>
                                <td><img src={profileImage} width={150} height={75} alt="Profile"/></td>
                                <td className="account-username">{user?.Username}</td>
                            </tr>
                            <tr>
                                <td className="account-label">Email</td>
                                <td className="account-answer">{user?.Email}</td>
                            </tr>
                            <tr>
                                <td className="account-label">Balance</td>
                                <td className="account-answer">${user?.Balance?.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td className="account-label">Debt</td>
                                <td className="account-answer">${user?.Debt?.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td className="account-label">Total Won</td>
                                <td className="account-answer">${user?.TotalWon?.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td className="account-label">Total Spent</td>
                                <td className="account-answer">${user?.TotalSpent?.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td className="account-label">Highest Win</td>
                                <td className="account-answer">${user?.HighestWin?.toFixed(2)}</td>
                            </tr>
                        </table>
                        <br/>
                        <ChangePassword user={user}/>
                        <br/>
                        <br/>
                        <Bankrupt user={user} onLogin={onLogin}/>
                        <br/>
                        <br/>
                        <button className="btn btn-lg btn-success btn-block" onClick={Logout}>Logout</button>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Account;


interface ChangePasswordProps {
    user: User | null;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({ user }) => {
    const [changePass, setCHangePass] = useState(false);
    const [message, setMessage] = useState("");

    function Change()
    {
        if (changePass)
        {
            setCHangePass(false);
        }
        else
        {
            setCHangePass(true);
        }
    }

    async function UpdatePassword()
    {
        const oldpassword = (document.getElementById("inputOldPassword") as HTMLInputElement | null)?.value;
        const newpassword = (document.getElementById("inputNewPassword") as HTMLInputElement | null)?.value;
        const confpassword = (document.getElementById("inputConfirmPassword") as HTMLInputElement | null)?.value;

        try
        {
            if (confpassword !== newpassword)
            {
                setMessage("Passwords do not match.");
            }
            else
            {
                let response = await UpdatePasswordRequest(user?.Username ?? '', oldpassword ?? '', newpassword ?? '');
    
                if (!response.ok) {
                    setMessage((await response.text()).toString());
                }
                else
                {
                    Change();
                }
            }
        }
        catch
        {
            setMessage("Error contacting server.");
        }
    }


    if (changePass === true)
    {
        return (
            <>
            <form onSubmit={UpdatePassword}>
            <input type="password" id="inputOldPassword" className="form-control" placeholder="Old Password" required />
            <br/>
            <input type="password" id="inputNewPassword" className="form-control" placeholder="New Password" required/>
            <br/>
            <input type="password" id="inputConfirmPassword" className="form-control" placeholder="Confirm New Password" required/>
            <br/>
            <p className="account-error">{message}</p>
            <button className="btn btn-lg btn-success btn-block" type="submit">Change</button>
            </form>
            </>
        );
    }
    else
    {
        return(
            <button className="btn btn-lg btn-success btn-block" onClick={Change}>Change Password</button>
        );
    }
}



interface BankruptProps {
    user: User | null;
    onLogin: (userData: any) => void;
}

const Bankrupt: React.FC<BankruptProps> = ({ user, onLogin }) => {
    const [message, setMessage] = useState("");

    function BankruptClick()
    {
        let modal = document.getElementById("myModal") as HTMLElement;
        let yesBtn = document.getElementById("yesBtn") as HTMLElement;
        let noBtn = document.getElementById("noBtn") as HTMLElement;
        let password = (document.getElementById("password") as HTMLInputElement)?.value;
        
        if (modal && yesBtn && noBtn) {
            modal.style.display = "block";
    
            yesBtn.onclick = async function() {
                modal.style.display = "none";

                let response = await ResetUser(user?.Username ?? '', password);

                if (response.ok)
                {
                    sessionStorage.clear();
                    onLogin(null);
                }
                else
                {
                    setMessage((await response.text()).toString());
                }
            };
    
            noBtn.onclick = function() {
                modal.style.display = "none";
            };
    
            window.onclick = function(event) {
                if (event.target === modal) {
                    modal.style.display = "none";
                }
            };
        } else {
            console.error("Modal or buttons not found.");
        }
    }

    return <>
     <button className="btn btn-lg btn-success btn-block" onClick={BankruptClick}>Declare Bankruptcy</button>
     <div id="myModal" className="account-modal">
        <div className="account-modal-content">
        <p style={{color: 'black'}}>Are you sure you want to proceed?</p>
        <p style={{color: 'black'}}>This will set your balance to $100 and reset all your stats.</p>
        <input type="password" id="password" className="form-control" placeholder="Password" required />
        <div className="account-modal-buttons">
            <button id="yesBtn" className="btn btn-lg btn-success btn-block">Yes</button>
            <button id="noBtn" className="btn btn-lg btn-success btn-block">No</button>
            <p className="account-error">{message}</p>
        </div>
        </div>
    </div>
    </>
}