export interface UserGet_User {
    Username: string;
    Balance?: number | null;
    TotalSpent?: number | null;
    TotalWon?: number | null;
    Debt?: number | null;
    HighestWin?: number | null;
}


export interface UsersList {
    users: UserGet_User[];
}

export async function GetUsers()
{
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    return await fetch('https://feedmyaddiction-db.dallasagsmith.workers.dev/user/get', {
        method: 'GET', 
        headers: headers,
    });
}