import "./allgames.css";
import { useEffect, useState } from "react";
import {User} from "./objects/UserLogin";
import { Game, GetGame } from "./objects/GameGet";
import { Link } from "react-router-dom";

interface AllGamesProps {
    user: User | null;
}



const AllGames: React.FC<AllGamesProps> = ({ user }) => {
    const [gameList, setGameList] = useState<Game[] | null>(null);

    useEffect(() => {
        const fetchGame = async () => {
            try {
                const response = await GetGame();
                const data: Game[] = await response.json();
                
                setGameList(data);
    
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
    
        fetchGame();
    }, []);

    return(
        <div className="account-body">
            <div className="account-container">
                <div className="account-form">
                    <h1>All Games</h1>
                    <div className="loan-card-container">
                        {gameList === null ?
                            (<div className="spinner-border text-success" role="status">
                                <span className="sr-only"></span>
                            </div>)
                            :
                            <>
                            {gameList?.map((game, index) => (
                                <div className="loan-card">
                                    <Link to={`/game?id=${game.ID}`}>
                                        <img src={`https://feedmyaddiction-db.dallasagsmith.workers.dev/games/load?username=${user?.Username}&gameid=${game.ID}&file=poster&type=png`} alt={game.Name} className="loan-card-image"/>
                                    </Link>
                                </div>
                            ))}
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AllGames;
