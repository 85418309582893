export async function RegisterUser(username: string, password: string, email: string) {
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    return await fetch('https://feedmyaddiction-db.dallasagsmith.workers.dev/user/create', {
        method: 'POST', 
        headers: headers,
        body: JSON.stringify({
            username: username,
            password: password,
            email: email,
        }),
    });
}

export async function TemporaryPassword(username: string) {
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    return await fetch('https://feedmyaddiction-db.dallasagsmith.workers.dev/user/temporary', {
        method: 'POST', 
        headers: headers,
        body: JSON.stringify({
            username: username,
        }),
    });
}

export async function UpdatePasswordRequest(username: string, password: string, newPassword: string) {
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    return await fetch('https://feedmyaddiction-db.dallasagsmith.workers.dev/user/update', {
        method: 'POST', 
        headers: headers,
        body: JSON.stringify({
            username: username,
            password: password,
            newpassword: newPassword
        }),
    });
}

export async function ResetUser(username: string, password: string) {
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    return await fetch('https://feedmyaddiction-db.dallasagsmith.workers.dev/user/reset', {
        method: 'POST', 
        headers: headers,
        body: JSON.stringify({
            username: username,
            password: password,
        }),
    });
}