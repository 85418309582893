import { UserGet_User, GetUsers } from './objects/UserGet';
import profileImage from './images/profile.png';
import { useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';

function Player() {
    const [user, setUser] = useState<UserGet_User>();
    const [userDebtPercent, setUSerDebtPercent] = useState(0);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const name = queryParams.get('name');


    useEffect(() => {
        const fetchUsers = async () => {
            try {
                
                const response = await GetUsers();
                const data: UserGet_User[] = await response.json();
                
                for (let i = 0; i < data.length; i++)
                {
                    if (data[i].Username === name)
                    {
                        setUser(data[i]);
                        if (((data[i].Balance ?? 0) - (data[i].Debt ?? 0)) < 0)
                        {
                            setUSerDebtPercent(100);
                        }
                        else
                        {
                            setUSerDebtPercent(Math.round(((data[i].Debt ?? 0) / (data[i].Balance ?? 0)) * 100 ));
                        }
                    }
                }

            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUsers();
    }, [name]);

    return(
        <div className="account-body">
            <div className="account-container">
                <div className="account-form">
                    <div className="account-card">
                        <h1>Player Details</h1>
                        <table>
                            <tr>
                                <td><img src={profileImage} width={150} height={75} alt="Profile"/></td>
                                <td className="account-username">{user?.Username}</td>
                            </tr>
                            <tr>
                                <td className="account-label">Balance</td>
                                <td className="account-answer">${user?.Balance?.toFixed(2)} → {userDebtPercent}% debt</td>
                            </tr>
                            <tr>
                                <td className="account-label">Debt</td>
                                <td className="account-answer">${user?.Debt?.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td className="account-label">Total Won</td>
                                <td className="account-answer">${user?.TotalWon?.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td className="account-label">Total Spent</td>
                                <td className="account-answer">${user?.TotalSpent?.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td className="account-label">Highest Win</td>
                                <td className="account-answer">${user?.HighestWin?.toFixed(2)}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Player;