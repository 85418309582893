export interface Loan {
    ID: number;
    Principle: number;
    AmountOwe: number;
    Discount: number;
}

export interface LoanContainer {
    loans: Loan[];
}

export async function GetLoans()
{
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    return await fetch('https://feedmyaddiction-db.dallasagsmith.workers.dev/loans/get', {
        method: 'GET', 
        headers: headers,
    });
}

export async function ClaimLoan(username: string, loanID: number)
{
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    return await fetch('https://feedmyaddiction-db.dallasagsmith.workers.dev/loans/claim', {
        method: 'POST', 
        headers: headers,
        body: JSON.stringify({
            username: username,
            loanID: loanID
        }),
    });
}

export async function PayLoan(username: string, amount: number)
{
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    return await fetch('https://feedmyaddiction-db.dallasagsmith.workers.dev/loans/pay', {
        method: 'POST', 
        headers: headers,
        body: JSON.stringify({
            username: username,
            amount: amount
        }),
    });
}