import { useState } from "react";
import "./login.css";
import {User, LoginUser} from "./objects/UserLogin";
import { Link, useNavigate } from 'react-router-dom';

interface LoginProps {
    onLogin: (userData: any) => void;
}


function Login({ onLogin }: LoginProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        setMessage("");
    
        //let username = document.getElementById("inputUsername")?.innerHTML;
        const password = (document.getElementById("inputPassword") as HTMLInputElement | null)?.value;
        const username = (document.getElementById("inputUsername") as HTMLInputElement | null)?.value;

        try
        {
            let response = await LoginUser(username ?? "", password ?? "");

            if (!response.ok) {
                setMessage((await response.text()).toString());
            }
            else
            {
                const data: User = await response.json();

                const userString = JSON.stringify(data);
                sessionStorage.setItem('user', userString);

                onLogin(data); 
                navigate("/");
            }
        }
        catch
        {
            setMessage("Error contacting server.");
        }

        setIsLoading(false);
    }


    return (
        <div className="login-body">
            <div className="login-container">
                <div className="login-form">
                    <h1>Sign In</h1>
                    <form className="form-signin" onSubmit={handleSubmit}>
                        <input type="text" id="inputUsername" className="form-control login-input" placeholder="Username" required />
                        <br/>
                        <input type="password" id="inputPassword" className="form-control" placeholder="Password" required/>
                        <br/>
                        <button className="btn btn-lg btn-success btn-block" type="submit">Sign in</button>
                        <br/>
                        <p className="login-error">{message}</p>
                        <br/>
                        <Loading loading={isLoading}/>
                        <br/>
                        <Link to="/register">Don't have an account? Sign up</Link>
                        <br/>
                        {/* <Link to="/">Forgot Password</Link> */}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login;



interface LoadingProps {
    loading: boolean;
}
  
const Loading: React.FC<LoadingProps> = ({ loading }) => {

    if (loading === true)
    {
        return (
        <div className="spinner-border text-success" role="status">
            <span className="sr-only"></span>
        </div>
        );
    }
    else
    {
        return(
        <></>
    );
    }
}